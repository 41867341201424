


































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import utils from '@/utils/util.ts'
import mineCaseGrid from '@/components/case/grid.vue'
import globalMixins from '@/mixins/global.ts'
import conf from '@/common/conf'

@Component({
  components: {
    mineCaseGrid
  },
  mixins: [globalMixins]
})
export default class ATSViewsCaseMine extends Vue<globalMixins> {
  @Action('caseList') caseList: Function
  @Action('caseDelBatch') caseDelBatch: Function
  @Action('createCase') createCase: Function
  @Getter('total') total: number
  @Getter('items') items: Object[]
  @Getter('fields') fields: any[]
  @Getter('newCaseId') newCaseId: number

  loading: boolean = false
  case_ids: any[] = []
  oneType: Array<any> = [{
    label: '已通过',
    name: 'approved'
  }, {
    label: '审核中',
    name: 'pending'
  }, {
    label: '被驳回',
    name: 'rejected'
  }, {
    label: '草稿',
    name: 'draft'
  }]
  query: any = {
    status: 'draft',
    keyword: '',
    is_mine: 1
  }
  anotherType: Array<any> = [{
    label: '被驳回',
    name: 'rejected'
  }, {
    label: '草稿',
    name: 'draft'
  }]
  TYPE: any[] = []
  pagination: any = {
    page: 1,
    size: 10
  }
  project: any = {}

  mounted () {
    this.project = JSON.parse(localStorage.getItem('projects'))
    this.query['projectId'] = this.project.id
    if (this.$route.query !== {} && this.$route.query.status) {
      this.query.status = this.$route.query.status
    }
    this.idealJudge()
    this.doSearch()
  }
  idealJudge () {
    if (conf.judgeDomation() === 'ideal' && this.project.role === 'doctor') {
      this.TYPE = this.anotherType
    } else {
      this.TYPE = this.oneType
    }
  }
  handleDel () {
    if (this.case_ids.length === 0) {
      this.errorMsg('请选择数据进行操作!')
      return
    }
    this.$confirm('已选中病例历将会被删除, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let params = {
        case_ids: this.case_ids,
        projectId: this.project.id
      }
      this.caseDelBatch({ params }).then(() => {
        this.successMsg('删除成功!')
        this.doSearch()
      })
    })
  }
  doKeywordSearch () {
    this.pagination.page = 1
    this.doSearch()
  }
  handleSelectionChange (ids) {
    this.case_ids = ids
  }
  onPagerSizeChange (val) {
    this.pagination.size = val
    this.doSearch()
  }
  onPagerCurrentChange (val) {
    this.pagination.page = val
    this.doSearch()
  }
  // 新增病历
  async handleAdd () {
    let params = {projectId: this.project.id}
    await this.createCase({ params })
    this.$router.push({ path: `/case/add/${this.newCaseId}?mine=1`})
  }
  handleStatusChange(tab, event) {
    this.query.status = tab.name
    this.doSearch()
  }
  doSearch () {
    this.loading = true
    let pagination = utils.pager(this.pagination.page, this.pagination.size)
    let params = Object.assign({}, this.query, {limit: pagination.limit}, {offset: pagination.offset}) 
    if (this.query.keyword === '') {
      delete params.keyword
    }
    this.caseList({ params }).then(() => {
      this.loading = false
    })
  }
}
