






























































import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component({
  props: {
    items: Array,
    fields: Array,
    select: Boolean
  }
})

export default class ATSComponentsMerberManagers extends Vue {
  multipleSelection: any = []
  case_ids: any = []

  handleSelectionChange(val) {
    this.case_ids = []
    val.map((v: {id: number}) => {
      this.case_ids.push(v.id)
    })
    this.$emit('handleSelectionChange', this.case_ids)
  }
}
